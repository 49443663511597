import React from 'react'
import {
    Page,
    BottomPage,
    Backgroundcolor,
    BackgroundImage,
    ContentContainer,
    Logo,
    Title,
    Subtitle,
    CtaButton,
    ButtonTextTypography,
    StyledTypographyLabel,
    StyledTypographyH1,
    CarouselContainer,
} from '@/app/ui-new/pages/landing/theme/purple/index.styled'
import { Footer } from '@/app/ui-new/pages/landing/components/Footer/Footer'
import CarriersCarousel from '@/app/ui-new/pages/landing/components/CarriersCarousel/CarriersCarousel'
import TestimonialsCarousel from '@/app/ui-new/pages/landing/components/TestimonialsCarousel/TestimonialsCarousel'
import { freightCarriers } from '@/app/ui-new/pages/landing/components/CarriersCarousel/helper'
import { Box } from '@material-ui/core'
import { Language } from '@lazr/enums'
import { useDispatch } from 'react-redux'
import { setRequestDemoDialogOpen as reduxSetRequestDemoOpen } from '@/app/ui/redux/actions/RequestDemoActions'
import { setRegisterDialogOpen as reduxSetRegisterDialogOpen } from '@/app/ui/redux/actions/RegisterActions'

/* I18N */
import i18n from './index.i18n'
import { Helmet } from 'react-helmet'
import CarriersCarouselDesktop from '../../../website/components/CariersCarousel/Desktop/CarriersCarouselDesktop'
import { parcelCarriers } from '../../../website/components/CariersCarousel/helper'

declare const window: Window & { dataLayer: Record<string, unknown>[] }

export const PurplePage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = i18n.activate(props.language.toLowerCase() as 'en' | 'fr')
    const dispatch = useDispatch()

    const urlParams = new URLSearchParams(window.location.search)

    return (
        <>
            <Helmet>
                <title>{t('Page Title')}</title>
                <meta charSet='utf-8' />
                <meta name='description' content={t('Meta Description')} />
            </Helmet>
            <Backgroundcolor />
            <BackgroundImage>
                <img alt='' className='arrows' src={`/images/landing/theme/purple/LP-Background.svg?v=${window.lazrVersion}`} />
            </BackgroundImage>
            <BackgroundImage>
                <img alt='' className='browser' src={`/images/landing/theme/purple/windows-landing-page.png?v=${window.lazrVersion}`} />
            </BackgroundImage>
            <Page>
                <Logo />
                <ContentContainer>
                    <Title>{props.title}</Title>
                    <Subtitle>{props.subtitle}</Subtitle>
                </ContentContainer>
                <Box mt={10} mb={17.75}>
                    <CtaButton
                        size='large'
                        endIcon={<img alt='LazrIcon' src={`/images/website/carousel/LazrBrandedArrowWhite.svg?v=${window.lazrVersion}`} />}
                        onClick={() => {
                            if (props.button.type === 'RequestDemo') {
                                dispatch(reduxSetRequestDemoOpen(true))
                            } else {
                                dispatch(reduxSetRegisterDialogOpen(true))
                            }

                            window.dataLayer.push({
                                event: 'landing_main_button',
                                landingPageTheme: urlParams.get('theme'),
                                landingPageButton: urlParams.get('button'),
                                landingPageSubtitle: urlParams.get('subtitle'),
                                landingPageTitle: urlParams.get('title'),
                                landingPageTestimonials: urlParams.get('testimonials'),
                                landingPageLanguage: urlParams.get('language'),
                            })
                        }}
                    >
                        <ButtonTextTypography variant={'h2'}>{props.button.text}</ButtonTextTypography>
                    </CtaButton>
                </Box>
            </Page>
            <BottomPage>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <CarouselContainer>
                        <CarriersCarouselDesktop
                            freightCarriers={freightCarriers}
                            parcelCarriers={parcelCarriers}
                            scrollableListWidth={900}
                            primaryLabel={'100+'}
                            secondaryLabel={t('Trusted carriers are on Lazr')}
                            carrier={'parcel'}
                        />
                    </CarouselContainer>
                </Box>
                <Footer language={props.language} />
            </BottomPage>
        </>
    )
}

export interface Testimonials {
    jobTitle: string
    name: string
    text: string
}
export interface Props {
    button: {
        text: string | JSX.Element
        type: string
    }
    language: Language
    subtitle: string | JSX.Element
    testimonials: Testimonials[]
    title: string
}
