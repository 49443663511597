import React from 'react'
import styled from 'styled-components'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab'

const getButtonHeight = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 56
        case 'small':
            return 26
        case 'medium':
        default:
            return 40
    }
}

const getFontSize = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 1
        case 'small':
            return 0.75
        case 'medium':
        default:
            return 0.875
    }
}

const getIconSize = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 1.5
        case 'small':
            return 0.875
        case 'medium':
        default:
            return 1.125
    }
}

const getButtonPadding = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 7.5
        case 'small':
            return 3
        case 'medium':
        default:
            return 5.75
    }
}

export const StyledToggleButtonGroup: React.FC<ToggleButtonGroupProps> = styled(ToggleButtonGroup) <ToggleButtonGroupProps>`
    height: ${(props): number => getButtonHeight(props.size)}px;
    border: 1px solid ${(props): number => props.theme.palette.neutral300.main};
    border-radius: 40px;

    & [class*="MuiToggleButton-label"] [class*="MuiSvgIcon-root"]{
        font-weight: 500;
        font-size: ${(props): number => getIconSize(props.size)}rem;
    }

    & [class*="MuiToggleButton-label"]{
         position: relative;
         top: 1px;
         text-transform: none;
         font-size: ${(props): number => getFontSize(props.size)}rem;
         letter-spacing: 0.02rem;
    }

    & [class*="MuiToggleButton-root"]{
         padding-left: ${(props): number => props.theme.spacing(getButtonPadding(props.size)) - 3}px;
         padding-right: ${(props): number => props.theme.spacing(getButtonPadding(props.size))}px;
    }

    &:hover{
        border: 1px solid ${(props): number => props.theme.palette.accentPrimary700.main};
    }
`

export const ToggleButtonGroupLabel = styled.div`
    margin-bottom: 1rem;
    /*position: absolute;
    top: 0;
    left: 0;*/
`

export const ToggleButtonGroupContainer  = styled.div`
    position: relative;
    font-family: 'Graphik', 'Helvetica', 'Arial', sans-serif;
    color: #262626;
    font-weight: 500;
`
