import { v4 as uuidv4 } from 'uuid'
import { deepCopy } from '@lazr/utilities'


// Temporarily disabled options
export const isReeferTypeEnabled = false
export const hazmatEnabled = false
export const stackableEnabled = false
export const isMultipleCarrierAcountsEnabled = false

// Initial states
export const initialErrorState = {
    packageTypeSelector: false,
    truckTypeSelector: false,
    equipmentTypeSelector: false,
    specializeTypeSelector: false,
    reeferTypeSelector: false,
    envelopeType: false,
    envelopeConfirmed: false,
    shipmentDocumentType: false,
    quantity: false,
    pieces: false,
    length: false,
    width: false,
    height: false,
    weight: false,
    description: false,
    poNumber: false,
    nmfc: false,
    packageQuestion: [],
    moreAboutQuestion: [],
    dryIceWeight: false,
    hazmatMode: false,
    hazmatClass: false,
    hazmatAccessibiltyType: false,
    freightHazmatUn: false,
    freightHazmatScientificName: false,
    freightHazmatPackagingGroup: false,
    freightHazmatEmergencyContactNumber: false,
    freightHazmatClass: false,
    batteryMaterial: false,
    batteryPacking: false,
}

export const initialFormControl = {
    uniqueKey: uuidv4(),
    isPackageTypeQuestionComplete: false,
    isMoreAboutQuestionComplete: false,
    handlingUnitExpandedByUser: false,
    isEditingPackageTypeQuestion: false,
    isEditingMoreAboutQuestion: false,
}

export const initialTruckState = {
    truckType: '',
    equipmentType: '',
    reeferType: '',
    transportType: null,
}

export const initialHandlingUnitState = {
    formControl: deepCopy(initialFormControl),
    packageTypeQuestion: {
        packageType: '',
        isOtherPackageType: false,
        packageAssociativeCode: -1,
    },
    moreAboutQuestion: {
        poNumber: '',
        envelopeType: '',
        measurements: {
            isImperial: true,
            quantity: -1,
            pieces: -1,
            length: -1,
            width: -1,
            height: -1,
            weight: -1,
            totalWeight: -1,
        },
        description: '',
        classification: {
            class:  '',
            nmfc:  '',
            sub: '',
            volume: 0,
            density: 0,
            autoCalculatedClass: '',
        },
        additionalServices: {
            hazmat: false,
            stackable: false,
            excessiveLengthAccessorial: false,
            excessiveLength: 0,
            excessiveHeightAccessorial: false,
            excessiveHeight: 0,
            dryIceAccessorial: false,
            dryIceWeight: 0,
            batteryAccessorial: false,
            hazmatMode: null,
            hazmatClass: null,
            hazmatAccessibiltyType: null,
            batteryMaterial: null,
            batteryPacking: null,
            freightHazmatUn: null,
            freightHazmatScientificName: null,
            freightHazmatPackagingGroup: null,
            freightHazmatEmergencyContactNumber: null,
            freightHazmatClass: null,
        },
        hazmatDetails: {
            idNumbers: '',
            packageGroup: '',
            containerType: '',
            hazClass: '',
            freightClass: '',
            sub: '',
            nmfc: '',
            totalPieces: '',
        },
    },
}

export const handlingUnitLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const carrierCodeMapping: Record<string, string> = {
    ['Manitoulin']: 'Manitoulin',
    ['GLS Canada']: 'GLS Canada',
    ['Purolator']: 'PRLA',
    ['Fastfrate']: 'CFFO',
    ['Canpar']: 'CNPR',
    ['Day & Ross Commerce']: 'DAYR',
    ['Fedex']: 'FXFE',
    ['Xpol']: 'XPOL',
    ['UPS']: 'UPS',
    ['Echo']: 'ECHO',
    ['Holland']: 'HMES',
    ['SynergieCanada']: 'Synergie Canada',
    ['Wwex']: 'WWEX',
    ['Canada Post']: 'Canada Post',
    ['AbfFreight']: 'ABFS',
    ['DHL']: 'DHL',
    ['Lazr']: 'Lazr Freight',
    ['SteelesTransfer']: 'SteelesTransfer',
    ['Labranche']: 'Labranche',
    ['WesternExpress']: 'WXSM',
    ['NewPenn']: 'NPME',
    ['JpsLogistique']: 'JPSE',
    ['ForwardAir']: 'FWDN',
    ['ViaExpress']: 'Via Express',
    ['EighteenWheels']: '18Wheels',
    ['Rosenau']: 'Rosenau',
    ['YrcFreight']: 'RDWY',
    ['RcTransport']: 'RC',
    ['Remco']: 'Remco',
    ['Teams']: 'TMBM',
    ['Rona']: 'Rona',
    ['Polaris']: 'POLT',
    ['Guilbault']: 'GBIT',
    ['Valois']: 'Valois',
    ['Tforce']: 'QXTI',
    ['Bourret']: 'TPBO',
    ['Speedy']: 'SZTG',
    ['Apex']: 'AXME',
    ['Chr']: 'RBTW',
    ['Transx']: 'TransX Intermodal',
    ['NmTransfer']: 'NMTF',
    ['Reddaway']: 'RETL',
    ['Gariepy']: 'Gariepy',
    ['Minimax']: 'MNME',
    ['MiniMax']: 'MiniMax',
    ['JaysTransportation']: 'JMSM',
    ['Vitran']: 'Vitran',
    ['Midland']: 'Midland',
    ['Estes']: 'EXLA',
    ['WesternCanadaExpress']: 'Western Canada Express Intermodal',
    ['Transkid']: 'Transkid',
    ['OldDominionFreight']: 'ODFL',
    ['Rci']: 'Transport RCI - EDI',
    ['IntegratedTransportationSolution']: 'ITSL',
    ['Nationex']: 'NINP',
    ['Ward']: 'WARD',
    ['Robert']: 'ROB1',
    ['TstOverland']: 'OVLD',
    ['Transplus']: 'Transplus',
    ['InterNord']: 'Inter-Nord',
    ['Normandin']: 'Normandin',
    ['Cascades']: 'CTNP',
    ['Bourassa']: 'TAGB',
}

export const DescriptionCharCount = 50
export const PoNumberCharCount = 25
