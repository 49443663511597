import { isInternationalOrder } from '@lazr/utilities'
import { Provider } from '@lazr/enums'
import { OrderInfo } from '@/app/model'
import { createPurolatorSpecificObject } from './components/Purolator/helpers'
import { createFedexSpecificObject } from './components/Fedex/helpers'
import { createCanadaPostSpecificObject } from './components/CanadaPost/helpers'
import { createCanparSpecificObject } from './components/Canpar/helpers'
import { createUPSSpecificObject } from './components/UPS/helpers'
import { createRoseRocketSpecificObject } from './components/RoseRocket/helpers'
import { createP44SpecificObject } from './components/P44/helpers'
import { createDhlSpecificObject } from './components/DHL/helpers'
import { createGlsCanadaSpecificObject } from './components/GlsCanada/helpers'
import { TransportType } from '@lazr/openapi-client'

export const createProviderSpecificObject = (order: Partial<OrderInfo>, provider: Provider | undefined) => {
    if (
        !isInternationalOrder(order.rfq?.originCountry?.code, order.rfq?.destinationCountry?.code)
        || order.rfq?.transportType.code === TransportType.ENVELOPE
    ) {
        return undefined
    }

    switch (provider) {
        case Provider.CANADA_POST:
            return createCanadaPostSpecificObject(provider, order)

        case Provider.PUROLATOR:
            return createPurolatorSpecificObject(provider, order)

        case Provider.FEDEX:
            return createFedexSpecificObject(provider, order)

        case Provider.CANPAR:
            return createCanparSpecificObject(provider, order)

        case Provider.UPS:
            return createUPSSpecificObject(provider, order)

        case Provider.ROSE_ROCKET:
            return createRoseRocketSpecificObject(provider, order)

        case Provider.PROJECT_44:
            return createP44SpecificObject(provider, order)

        case Provider.DHL:
            return createDhlSpecificObject(provider, order)

        case Provider.GLS_CANADA:
            return createGlsCanadaSpecificObject(provider, order)

        default:
            return undefined
    }
}
