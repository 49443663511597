import React from 'react'
import { Box } from '@material-ui/core'
import {
    StyledContainer,
    StyledTypographyH1,
    StyledTypographyLabel,
} from '@/app/ui-new/pages/website/components/CariersCarousel/Desktop/CarriersCarouselDesktop.styled'
import ScrollableListDesktop from '@/app/ui-new/pages/website/components/CariersCarousel/Desktop/ScrollableListDesktop'

const CarriersCarouselDesktop: React.FunctionComponent<Props> = (props: Props) => (
    <StyledContainer width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
        <StyledContainer display='flex' alignItems='center' width={1224} height={150}>
            <Box>
                <StyledTypographyH1 variant='h1'>{props.primaryLabel}</StyledTypographyH1>
                <StyledTypographyLabel variant='body2'>{props.secondaryLabel}</StyledTypographyLabel>
            </Box>

            <ScrollableListDesktop
                freightCarriers={props.freightCarriers}
                parcelCarriers={props.parcelCarriers}
                listWidth={props.scrollableListWidth}
                carrier={props?.carrier}
                />
        </StyledContainer>
    </StyledContainer>
)


export interface Props {
    freightCarriers: string[]
    parcelCarriers: string[]
    scrollableListWidth: number
    primaryLabel?: string
    secondaryLabel?: string
    carrier?: 'freight' | 'parcel'
}

export default CarriersCarouselDesktop
