import styled from 'styled-components'
import {
    Typography as MuiTypography,
    Grid as MuiGrid,
    InputLabel as MuiInputLabel,
    TextField as MuiTextField,
    OutlinedTextFieldProps,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const Label = styled(MuiInputLabel)<{$disabled: boolean}>`
    margin-bottom: ${(props): number => props.theme.spacing(3.5)}px;
    color: ${(props): string => props.$disabled ? props.theme.palette.neutral200.main : props.theme.palette.neutral800.main };
    font-weight: 500;
`

export const Typography = styled(MuiTypography)(MuiSpacing)

export const TextField = styled(MuiTextField)<OutlinedTextFieldProps>`
  /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

& [class^="MuiFormHelperText-root"] {
    color: ${(props): number => props.theme.palette.neutral500.main};
    margin-left: 0;
    margin-top: ${(props): number => props.theme.spacing(2)}px;

    &.Mui-disabled {
        color: ${(props): number => props.theme.palette.neutral400.main};
    }

    &.Mui-error {
        color: ${(props): number => props.theme.palette.error900.main};
    }
}
& [class^="MuiFormLabel-root"] {
    top: 52px;
    color: ${(props): number => props.theme.palette.neutral500.main};

    &.Mui-disabled {
        color: ${(props): number => props.theme.palette.neutral300.main};
    }
}

& [class^="MuiInputAdornment-root"] {
     height: 100%;

     > p {
         font-size: 1.25rem;
         color: ${(props): number => props.theme.palette.neutral700.main};
         padding-top: ${(props): number => props.theme.spacing(0.25)}px;;
     }

      svg {
        color: ${(props): number => props.theme.palette.neutral700.main};
        width: 0.75em;
    }
}

& [class^="MuiInputBase-root"] {
    height: ${(props): string => props.size === 'small' ? 'auto' : '67px' };
    width: ${(props): string => props.fullWidth ? '100%' : props.size === 'small' ? 'auto' : '128px' };
    font-size: ${(props): string => props.size === 'small' ? '1rem!important' : '1.7rem' };
    font-weight: ${(props): number => props.theme.typography.fontWeightRegular};
    align-items: flex-start;
    input {
        caret-color: ${(props): number => props.theme.palette.accentPrimary700.main};

        ::placeholder {
            color: ${(props): number => props.theme.palette.neutral500.main};
        }
        padding-top: ${(props): number => props.theme.spacing(2.5)}px;
    }

    &:hover fieldset {
       border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
    }

    &.Mui-focused fieldset {
        border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
    }

    &.Mui-error {
        fieldset {
            border-color: ${(props): number => props.theme.palette.error900.main};
        }
        input {
            caret-color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    &.Mui-disabled {
        background-color: ${(props): number => props.theme.palette.neutral.main};
        input::placeholder {
            color: ${(props): number => props.theme.palette.neutral300.main};

        }

        [class^="MuiInputAdornment-root"] * {
            color: ${(props): number => props.theme.palette.neutral300.main};
        }

        fieldset {
            border-color: ${(props): number => props.theme.palette.neutral.main};
        }
    }
}
`

export const Grid = styled(MuiGrid)(MuiSpacing)
