// MarketPlace
// todo: once we are 100% on new UI, constant values below can have leading underscores removed
export const SET_MARKET_PLACE_ADDRESS_INFORMATION = '__SET_MARKET_PLACE_ADDRESS_INFORMATION'
export const SET_MARKET_PLACE_RFQ_INFORMATION = '__SET_MARKET_PLACE_RFQ_INFORMATION'
export const SET_MARKET_PLACE_ADDRESS_ACCESSORIALS = '__SET_MARKET_PLACE_ADDRESS_ACCESSORIALS'
export const SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS = '__SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS'
export const SET_MARKET_PLACE_CARGO_ACCESSORIALS = '__SET_MARKET_PLACE_CARGO_ACCESSORIALS'
export const SET_MARKET_PLACE_ORDER_INFORMATION = '__SET_MARKET_PLACE_ORDER_INFORMATION'
export const SET_MARKET_PLACE_TRANSPORT_INFORMATION = '__SET_MARKET_PLACE_TRANSPORT_INFORMATION'
export const FILL_MARKETPLACE_TRIGGER = '__FILL_MARKETPLACE_TRIGGER'
export const SET_MARKET_PLACE = '__SET_MARKET_PLACE'
export const PRELOAD_MARKET_PLACE = '__PRELOAD_MARKET_PLACE'
export const SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE = '__SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE'
export const SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE = '__SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE'
export const SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE_CURRENCY = '__SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE_CURRENCY'
export const SET_MARKET_PLACE_COMMODITY_TYPE = '__SET_MARKET_PLACE_COMMODITY_TYPE'
export const SET_MARKET_PLACE_BILLING_CURRENCY = '__SET_MARKET_PLACE_BILLING_CURRENCY'
export const SET_MARKET_PLACE_SELECTED_QUOTE = '__SET_MARKET_PLACE_SELECTED_QUOTE'
export const SET_MARKET_PLACE_QUOTES = '__SET_MARKET_PLACE_QUOTES'
export const SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE = '__SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE'
export const SET_MARKET_PLACE_HANDLING_UNIT = '__SET_MARKET_PLACE_HANDLING_UNIT'
export const DELETE_MARKET_PLACE_HANDLING_UNIT = '__DELETE_MARKET_PLACE_HANDLING_UNIT'
export const RESET_MARKET_PLACE_CARGO = '__RESET_MARKET_PLACE_CARGO'
export const RESET_MARKET_PLACE_DUTY_BILL_ADDRESS = '__RESET_MARKET_PLACE_DUTY_BILL_ADDRESS'
export const RESET_MARKET_PLACE_CUSTOMS_DETAILS = '__RESET_MARKET_PLACE_CUSTOMS_DETAILS'
export const RESET_MARKET_PLACE_HAZMAT_DETAIL = '__RESET_MARKET_PLACE_HAZMAT_DETAIL'
export const RESET_MARKET_PLACE_CUSTOMS_INFO = '__RESET_MARKET_PLACE_CUSTOMS_INFO'
export const RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS = '__RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS'
export const ADD_MARKET_PLACE_CUSTOMS_DETAIL = '__ADD_MARKET_PLACE_CUSTOMS_DETAIL'
export const DELETE_MARKET_PLACE_CUSTOMS_DETAIL = '__DELETE_MARKET_PLACE_CUSTOMS_DETAIL'
export const MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL = '__MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL'
export const SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL = '__SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL'
export const SET_MARKET_PLACE_PLACES_AUTOCOMPLETE = '__SET_MARKET_PLACE_PLACES_AUTOCOMPLETE'
export const SET_MARKET_PLACE_MARKET_PLACE_STEP = '__SET_MARKET_PLACE_MARKET_PLACE_STEP'
export const SET_MARKET_PLACE_COMPLETED_STEP = '__SET_MARKET_PLACE_COMPLETED_STEP'
export const SET_MARKET_PLACE_ACCESSORIAL_LISTS = '__SET_MARKET_PLACE_ACCESSORIAL_LISTS'
export const SET_COUNTRY_LIST = '__SET_COUNTRY_LIST'
export const SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST = '__SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST'
export const SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST = '__SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST'
export const SET_MARKET_PLACE_PACKAGE_TYPE_LIST = '__SET_MARKET_PLACE_PACKAGE_TYPE_LIST'
export const SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST = '__SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST'
export const SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE = '__SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE'
export const SET_MARKET_PLACE_QUOTE_FETCHING_RESULTS_COMPLETED = '__SET_MARKET_PLACE_QUOTE_FETCHING_RESULTS_COMPLETED'
export const SET_MARKET_PLACE_QUOTE_FETCHING_SEARCH_PROGRESS = '__SET_MARKET_PLACE_QUOTE_FETCHING_SEARCH_PROGRESS'
export const SET_MARKET_PLACE_SCHEDULE_PICKUP = '__SET_MARKET_PLACE_SCHEDULE_PICKUP'
export const SET_MARKET_PLACE_ERRORS = '__SET_MARKET_PLACE_ERRORS'
export const SET_MARKET_PLACE_ERRORS_IS_SUBMITTED = '__SET_MARKET_PLACE_ERRORS_IS_SUBMITTED'
export const SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN = '__SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN'
export const SET_MARKET_PLACE_INVALIDATION_DIALOG = '__SET_MARKET_PLACE_INVALIDATION_DIALOG'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK
    = '__SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK
    = '__SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS
    = '__SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS
    = '__SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS'
export const SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED
    = '__SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED'
export const SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED
    = '__SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED'
export const SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED = '__SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED'
export const SET_PROMPT_MODAL_LEAVE = '__SET_PROMPT_MODAL_LEAVE'
export const TOGGLE_REASONS_MODAL = '__TOGGLE_REASONS_MODAL'
export const TOGGLE_SAME_DAY_GROUND_SHIPMENT_MODAL = '__TOGGLE_SAME_DAY_GROUND_SHIPMENT_MODAL'
export const SET_PAYMENT_TYPE = '__SET_PAYMENT_TYPE'
export const SET_COLLECT_INFORMATION = '__SET_COLLECT_INFORMATION'
export const SET_USER_LANES = '__SET_USER_LANES'
export const SET_USER_SELECTED_LANE = '__SET_USER_SELECTED_LANE'
export const SET_NEW_PICKUP_DATE = '_SET_NEW_PICKUP_DATE'
export const SET_PROMPT_UNSAVED_CHANGES_DIALOG = '_SET_PROMPT_SAVE_CHANGES_DIALOG'


export const SET_MARKET_PLACE_SHIPMENT_COVERAGE = '__SET_MARKET_PLACE_SHIPMENT_COVERAGE'
export const SET_PRICE_RESET_TRIGGERED = '__SET_PRICE_RESET_TRIGGERED'

export const SET_TOGGLE_WARNING_GROUND_SHIPMENT_MODAL = '__SET_TOGGLE_WARNING_GROUND_SHIPMENT_MODAL'


export interface Action {
    type: string
    payload: any
}
