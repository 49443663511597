/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'

import { Strong } from '../../../AppLayout.styled'

export default ((): Translations<typeof en> => ({
    ['New Version Available']: 'Nouvelle version disponible',
    ['Reload Lazr at your convenience for the latest version.']: 'Rechargez Lazr à votre convenance pour la dernière version.',
    ['Reload']: 'Recharger',
    Attention: 'Attention',
    message: () => (
        <>
            <p>
                <Strong>MISE À JOUR DE LA DISPONIBILITÉ DU SERVICE – GRÈVE DE POSTES CANADA</Strong>
            </p>
            <p>
                <Strong>UPS:</Strong> Ramassage sur appel pour les envois terrestres nationaux suspendu jusqu'à nouvel ordre. Ramassages
                quotidiens non affectés. Services express non affectés.
            </p>
            <p>
                <Strong>FEDEX:</Strong> Ramassage le jour même pour les services express suspendu jusqu'à nouvel ordre. Services terrestres
                non affectés.
            </p>
            <p>
                <Strong>PUROLATOR:</Strong> Limite quotidienne sur tous les services en place. Le service pourrait ne pas être disponible.
            </p>
            <p>
                <Strong>GLS CANADA:</Strong> Tous les services sont indisponibles en raison d'une surcharge du réseau de l'opérateur.
            </p>
        </>
    ),
}))()
