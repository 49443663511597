
export const configNotificationCookiesMessage = (cookiename: string) : boolean=>{


const cookieName = cookiename
const currentDate = new Date().toISOString().split('T')[0]

// Function to get a cookie value by name
const getCookie = (name: string) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    return match ? match[2] : null
}

// Check if the message was already shown today
const lastShownDate = getCookie(cookieName)
let messageSnakbarOpen = false

if (lastShownDate !== currentDate) {
    // Show the message
     messageSnakbarOpen = true
}
return messageSnakbarOpen

}